const {
  RELATIONSHIP_LABEL: {
    EDGE
  },
  PermissionHelper: {
    permissionError,
    isControlAreaAdmin
  },
  RelationshipHelper
} = require('@rss/common');
const {
  LibraryPermissionHelper
} = require('@risk-and-safety/library');
module.exports.permissionError = permissionError;
module.exports.isInventorySpecialist = (campusCode, user) => RelationshipHelper.hasRelationshipWithRole(campusCode, user.relationships, EDGE.INVENTORY_SPECIALIST);
module.exports.isChemAdmin = (campusCode, user) => RelationshipHelper.hasRelationshipWithRole(campusCode, user.relationships, EDGE.CHEMICAL_ADMIN) || RelationshipHelper.hasAnyOfRoles(user.relationships, [EDGE.CHEMICAL_ADMIN]);
module.exports.isChemAdminReadOnly = (campusCode, user) => RelationshipHelper.hasRelationshipWithRole(campusCode, user.relationships, EDGE.CHEMICAL_ADMIN_READ_ONLY);
module.exports.isChemInfo = user => RelationshipHelper.hasAnyOfRoles(user.relationships, [EDGE.CHEM_INFO]);
module.exports.hasChemAdminRead = (campusCode, user) => module.exports.isChemAdminReadOnly(campusCode, user) || module.exports.isChemAdmin(campusCode, user);
module.exports.hasReadAccessToContainer = (container, user) => module.exports.hasChemAdminRead(container.inventory.campusCode, user) || module.exports.hasWriteAccessToContainer(container, user) || !container.isPrivate && user.inventories.reduce((acc, inventory) => acc.concat(inventory.colleagues.reduce((inventoryAcc, colleague) => inventoryAcc.concat(colleague._id.toString()), [])), []).includes(container.inventory._id.toString()) || LibraryPermissionHelper.isMemberOfInventory(container.inventory._id.toString(), user, container.inventory.campusCode);
module.exports.hasWriteAccessToContainer = (container, user) => (user.inventories || [{}]).some(inventory => inventory._id && inventory._id.toString() === container.inventory._id.toString()) || module.exports.isChemAdmin(container.inventory.campusCode, user) || LibraryPermissionHelper.isMemberOfInventory(container.inventory._id.toString(), user, container.inventory.campusCode);
module.exports.hasReadAccessToLibrary = user => module.exports.isChemAdmin(user.campusCode, user) || module.exports.hasChemAdminRead(user.campusCode, user) || module.exports.isChemInfo(user) || isControlAreaAdmin(user, user.campusCode);
module.exports.hasWriteAccessToLibrary = user => module.exports.isChemInfo(user);