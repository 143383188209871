import { useQuery } from '@apollo/client';
import { RELATIONSHIP_LABEL, TENANTS } from '@rss/common';
import { isEmpty, orderBy } from 'lodash';
import { useState } from 'react';

import { TENANTS_QUERY } from '../graphql/tenants.query';
import { isTenantsAPIEnabled } from '../shared/helper';
import useProfile from './useProfile';

const {
  NODE: { GLOBAL },
} = RELATIONSHIP_LABEL;

// TODO: Will remove `includeGlobal` once TemplateFilter component is modified
function getTenantList(includeGlobal) {
  let tenantList = this.TENANTS.map(({ tenantCode, name }) => ({
    tenantCode,
    name,
  }));

  if (!includeGlobal) {
    tenantList = this.TENANTS.filter(({ tenantCode }) => tenantCode !== GLOBAL).map(({ tenantCode, name }) => ({
      tenantCode,
      name,
    }));
  }

  return orderBy(tenantList, 'tenantCode');
}

function getTenant(tenantCode) {
  const tenant = this.TENANTS.find((t) => t.tenantCode === tenantCode);
  return tenant || null;
}

function getCampus(campusCode) {
  const tenant = this.TENANTS.find((t) => t.campusList.some((c) => c.campusCode === campusCode));
  return tenant ? tenant.campusList.find((c) => c.campusCode === campusCode) : null;
}

function getTenantByCampusCode(campusCode) {
  return this.TENANTS.find((t) => t.campusList.some((c) => c.campusCode === campusCode));
}

function getCampusesByTenantCode(tenantCode) {
  const tenant = this.TENANTS.find((c) => c.tenantCode === tenantCode);
  return orderBy(tenant ? tenant.campusList : [], 'name');
}

function getDefaultCampus(tenantCode, campusCode, user) {
  const campuses = this.getCampusesByTenantCode(tenantCode);

  if (campusCode && campusCode !== 'ALL' && campuses?.some((c) => c.campusCode === campusCode)) {
    return campusCode;
  }

  if (user?.tenantCode === tenantCode) {
    return user?.campusCode || campuses[0]?.campusCode;
  }

  return campuses[0]?.campusCode;
}

function getDomain(campusCode, domainId) {
  const { domains = [] } = this.getCampus(campusCode) || {};
  return isEmpty(domains) ? null : domains.find((d) => d.id === domainId);
}

function getDefaultDomain(tenantCode, campusCode, domainId, user) {
  const defaultCampusCode = this.getDefaultCampus(tenantCode, campusCode, user);

  const { domains = [] } = this.getCampus(defaultCampusCode) || {};
  if (isEmpty(domains)) return null;

  if (domainId && domainId !== 'ALL' && domains.some((d) => d.id === domainId)) {
    return domainId;
  }

  return domains[0].id;
}

function buildPageTitle(titleText, campusCode, domainId) {
  if (domainId) {
    const domainName = this.getDomain(campusCode, domainId)?.name ?? '';
    if (domainName) return `${titleText} (${domainName})`;
  }

  if (campusCode) {
    const shortName = this.getCampus(campusCode)?.shortName ?? '';
    if (shortName) return `${shortName} ${titleText}`;
  }

  return titleText;
}

function institutionByCampusCode(campusCode) {
  const tenant = this.getTenantByCampusCode(campusCode);
  const campus = this.getCampus(campusCode);
  if (!campus) return null;

  return tenant.campusList.filter((c) => c.institution && c.institution === campus.institution);
}

function isMainCampus(campusCode) {
  return !campusCode.match(/(_H)|(_h)/);
}

export default function useTenants() {
  const [tenantList, setTenantList] = useState(TENANTS.getTenants());
  const { profile } = useProfile();

  useQuery(TENANTS_QUERY, {
    skip: !isTenantsAPIEnabled(profile),
    onCompleted: (data) => setTenantList(JSON.parse(JSON.stringify(data?.tenants || []))),
  });

  return {
    TENANTS: tenantList,
    getTenantList,
    getTenant,
    getTenantByCampusCode,
    getCampus,
    getCampusesByTenantCode,
    getDefaultCampus,
    getDomain,
    getDefaultDomain,
    buildPageTitle,
    isMainCampus,
    institutionByCampusCode,
  };
}
