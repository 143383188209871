import { Divider, Icon, IconButton, Tooltip, Typography } from '@material-ui/core';
import { FqHelper, FqType } from '@rss/common';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React from 'react';

import { MARKDOWN_OPTIONS } from '../../shared/helper';
import IconEmpty from '../IconEmpty';
import RichTextEditor from '../RichTextEditor';

const FqPrintBasic = ({
  type,
  info,
  label,
  question,
  response,
  parentResponses,
  hideCompleteCheck,
  headerLabel,
  optional,
}) => {
  const markdownLabel = [
    label?.trim(),
    !hideCompleteCheck &&
    ((!optional && !FqHelper.hasValue(response)) ||
      (question && FqHelper.hasError(question, !isEmpty(parentResponses) ? parentResponses : response)))
      ? '<span className="ml-10 inline italic text-danger-800">Incomplete</span>'
      : '',
  ]
    .join('')
    .trim();

  const hasLabel = markdownLabel?.length > 0;

  let responseValue = response;
  if ([FqType.LIBRARYTYPE, FqType.PERMIT_SITE].includes(type)) {
    responseValue = null;
  } else if (Array.isArray(response) && ![FqType.LIBRARYTYPE, FqType.PERMIT_SITE].includes(type)) {
    responseValue = response.map((option) => (
      <li key={option.value} className="pl-10 text-justify print:font-medium">
        {option.label}
      </li>
    ));
  } else if (
    !FqHelper.hasValue(response) &&
    hasLabel &&
    ![FqType.DEPENDENT, FqType.LABEL, FqType.ADD_ANOTHER, FqType.YYYY, FqType.RICHTEXT].includes(type)
  ) {
    responseValue = <IconEmpty classes={{ icon: '-mt-4 -mb-8' }} />;
  }

  return (
    <>
      {(hasLabel || responseValue) && (
        <div className="mb-8 w-auto py-0 pl-0">
          {hasLabel && (
            <div className="mt-2">
              <div className={clsx('items-middle flex leading-normal text-black', headerLabel && 'mt-16')}>
                <Markdown options={MARKDOWN_OPTIONS} className={clsx('font-medium', headerLabel && 'text-20')}>
                  {markdownLabel}
                </Markdown>

                {info && info.trim() && (
                  <Tooltip
                    classes={{
                      tooltip: 'max-w-screen-sm pre-line text-black inline-block bg-gray-50 shadow',
                      arrow: 'text-gray-50',
                    }}
                    className="ml-5 p-0"
                    title={<Markdown options={MARKDOWN_OPTIONS}>{info}</Markdown>}
                    placement="bottom"
                    interactive
                    arrow>
                    <IconButton color="inherit">
                      <Icon fontSize="small">info_outlined</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              {headerLabel && <Divider light className="mb-8 mt-4" />}
            </div>
          )}
          {responseValue && (
            <>
              <Typography
                component="div"
                className={clsx('whitespace-pre-wrap text-justify', hasLabel && type === FqType.RICHTEXT && '-mt-16')}>
                {type !== FqType.RICHTEXT ? responseValue : <RichTextEditor value={responseValue} />}
              </Typography>
            </>
          )}
          {!responseValue && (
            <>
              {type === FqType.RICHTEXT ? (
                <Typography component="div" className="whitespace-pre-wrap text-justify">
                  <IconEmpty classes={{ icon: '-mt-4 -mb-8' }} />
                </Typography>
              ) : null}
            </>
          )}
        </div>
      )}
    </>
  );
};

FqPrintBasic.propTypes = {
  type: PropTypes.string,
  question: PropTypes.objectOf(PropTypes.any),
  hideCompleteCheck: PropTypes.bool,
  headerLabel: PropTypes.bool,
  label: PropTypes.string.isRequired,
  info: PropTypes.string,
  optional: PropTypes.bool,
  response: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  parentResponses: PropTypes.objectOf(PropTypes.any),
};

FqPrintBasic.defaultProps = {
  type: '',
  question: {},
  hideCompleteCheck: false,
  headerLabel: false,
  optional: false,
  response: null,
  parentResponses: {},
  info: null,
};

export default FqPrintBasic;
