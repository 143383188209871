import { Typography } from '@material-ui/core';
import { FqType } from '@rss/common';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import FqQuestionFactory from './FqQuestionFactory';
import FqQuestionPrint from './FqQuestionPrint';

import { QUESTION_VARIANT } from './Helper';

const FqForm = ({
  questions,
  title,
  readOnly,
  disabled,
  responses,
  errors,
  shouldValidate,
  onChange,
  canComment,
  onComment,
  labelVariant,
}) => {
  const [validateForm, setValidateForm] = useState(shouldValidate);

  useEffect(() => {
    setValidateForm(shouldValidate);
  }, [shouldValidate]);

  const handleChange = (updates) => {
    if (!onChange) {
      return;
    }
    onChange(updates);
  };

  const getFormQuestion = (question) => {
    if (readOnly && question.type !== FqType.PERMIT_SEARCH) {
      return (
        <FqQuestionPrint
          question={question}
          response={(responses && responses[question.key]) || null}
          parentResponses={responses}
          headerLabel
          hideCompleteCheck
          key={`fq${question.key}`}
        />
      );
    }

    return (
      <FqQuestionFactory
        type={question.type}
        className={question.className ?? 'w-full'}
        key={`fq${question.key}`}
        required={question.required}
        disabled={question.disabled ?? disabled}
        question={question}
        onChange={handleChange}
        labelVariant={labelVariant}
        value={(responses && responses[question.key]) || null}
        parentResponses={responses}
        inputErrors={(errors && errors[question.key]) || []}
        shouldValidate={validateForm}
        variant={QUESTION_VARIANT.COMPACT}
        canComment={canComment}
        onComment={onComment}
        delay
      />
    );
  };

  const formQuestions = questions.map(getFormQuestion);

  return (
    <fieldset className="mx-24 mb-24 flex flex-col" disabled={disabled}>
      {title && (
        <div className="z-0 py-16 pl-8 pt-24">
          <Typography variant="h6" className="font-normal">
            {title}
          </Typography>
        </div>
      )}
      <div className={clsx('flex flex-wrap', readOnly && 'mt-16')}>{formQuestions}</div>
    </fieldset>
  );
};

FqForm.propTypes = {
  title: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  questions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
  responses: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.any),
  shouldValidate: PropTypes.bool,
  canComment: PropTypes.bool,
  onComment: PropTypes.func,
  labelVariant: PropTypes.oneOf(['default', 'long']),
};

FqForm.defaultProps = {
  title: null,
  readOnly: false,
  disabled: false,
  responses: {},
  errors: {},
  shouldValidate: false,
  canComment: false,
  onComment: () => null,
  labelVariant: 'default',
};

export default FqForm;
