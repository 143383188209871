import React from 'react';
import PropTypes from 'prop-types';

import { US_STATES } from '@rss/common';
import FqInputBox from './FqInputBox';
import FqDropdown from './FqDropdown';
import { QUESTION_VARIANT } from './Helper';

const FqAddressInput = ({ disabled, onChange, questionKey, required, value }) => {
  const handleChange = (fieldValue, addressField) => {
    onChange(
      {
        ...value,
        [addressField]: fieldValue,
      },
      questionKey,
    );
  };

  return (
    <div className="w-full">
      <div className="grid">
        <FqInputBox
          aria-label="Address Line 1"
          disabled={disabled}
          fullWidth
          inputProps={{ maxLength: 50 }}
          onChange={handleChange}
          placeholder="Line 1"
          questionKey="addressLine1"
          required={required}
          value={value?.addressLine1}
        />
        <FqInputBox
          aria-label="Address Line 2"
          disabled={disabled}
          fullWidth
          inputProps={{ maxLength: 50 }}
          onChange={handleChange}
          placeholder="Line 2"
          questionKey="addressLine2"
          required={required}
          value={value?.addressLine2}
        />
      </div>
      <div className="mx-16 md:block lg:grid lg:grid-cols-12">
        <div className="lg:col-span-5">
          <FqInputBox
            aria-label="City"
            disabled={disabled}
            fullWidth
            inputProps={{ maxLength: 50 }}
            onChange={handleChange}
            questionKey="city"
            placeholder="City"
            required={required}
            value={value?.city}
            variant={QUESTION_VARIANT.COMPACT}
          />
        </div>
        <div className="lg:col-span-3">
          <FqDropdown
            disabled={disabled}
            label="State"
            labelVariant="long"
            placeholder="State"
            onChange={handleChange}
            options={US_STATES.map(({ abbreviation }) => abbreviation)}
            questionKey="state"
            required={required}
            value={value?.state}
            valueType="string"
            variant={QUESTION_VARIANT.COMPACT}
          />
        </div>
        <div className="lg:col-span-4">
          <FqInputBox
            disabled={disabled}
            inputProps={{ maxLength: 10 }}
            onChange={handleChange}
            placeholder="ZIP Code"
            questionKey="zip"
            required={required}
            value={value?.zip}
            variant={QUESTION_VARIANT.COMPACT}
          />
        </div>
      </div>
    </div>
  );
};

FqAddressInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  questionKey: PropTypes.string.isRequired,
  value: PropTypes.shape({
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
};

FqAddressInput.defaultProps = {
  disabled: false,
  required: false,
  value: {},
};

export default FqAddressInput;
