import FuseAnimate from '@fuse/core/FuseAnimate';
import { Backdrop, Button, Icon, IconButton, Input, Paper, ThemeProvider, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { Children, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectMainTheme } from '../store/fuse/settingsSlice';

const AdvancedSearch = ({
  autoFocus,
  children,
  classes,
  inputValue,
  tooltip,
  onChange,
  onReset,
  onSearch,
  placeholder,
}) => {
  const mainTheme = useSelector(selectMainTheme);
  const [advancedSearch, setShowAdvancedSearch] = useState(false);

  const showAdvSearchArrow = Boolean(Children.toArray(children).filter(Boolean).length);

  return (
    <div className="z-10 w-full max-w-256 sm:max-w-xs md:max-w-sm">
      <ThemeProvider theme={mainTheme}>
        <FuseAnimate animation="transition.slideDownIn" delay={300}>
          <Paper
            className={clsx(
              'flex h-56 w-full max-w-512 items-center px-8 py-4 transition-rounded',
              classes.paper,
              advancedSearch && 'rounded-b-none',
            )}
            elevation={1}>
            <Icon className="mx-8" color="action">
              search
            </Icon>
            <div className="flex w-full flex-wrap overflow-x-hidden">
              <Input
                autoComplete="off"
                className="pr-8"
                autoFocus={autoFocus}
                id="advanced_search"
                onChange={onChange}
                value={inputValue || ''}
                placeholder={placeholder}
                type="search"
                fullWidth
                disableUnderline
                inputProps={{ 'aria-label': 'Search' }}
              />
            </div>
            {showAdvSearchArrow && (
              <Tooltip title={tooltip} arrow>
                <IconButton aria-label="advanced-search" onClick={() => setShowAdvancedSearch(!advancedSearch)}>
                  <Icon>{advancedSearch ? 'arrow_drop_up' : 'arrow_drop_down'}</Icon>
                </IconButton>
              </Tooltip>
            )}
          </Paper>
        </FuseAnimate>
        {advancedSearch ? (
          <div className="absolute z-10 w-full max-w-256 sm:max-w-xs md:max-w-sm">
            {onSearch && (
              <Backdrop
                open={advancedSearch}
                invisible
                onClick={() => {
                  setShowAdvancedSearch(false);
                  onSearch();
                }}
              />
            )}
            <FuseAnimate animation="transition.slideDownIn" duration={300}>
              <Paper className="-mt-4 flex flex-col rounded-t-none px-16">
                {children}
                <div className="flex justify-end py-16">
                  {onReset && (
                    <Button
                      onClick={() => {
                        onReset();
                        setShowAdvancedSearch(false);
                      }}
                      className="mr-12"
                      variant="contained">
                      Reset
                    </Button>
                  )}
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!onSearch}
                    onClick={() => {
                      onSearch();
                      setShowAdvancedSearch(false);
                    }}>
                    Filter
                  </Button>
                </div>
              </Paper>
            </FuseAnimate>
          </div>
        ) : null}
      </ThemeProvider>
    </div>
  );
};

AdvancedSearch.propTypes = {
  autoFocus: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.shape({ paper: PropTypes.string }),
  inputValue: PropTypes.string,
  tooltip: PropTypes.string,
  onChange: PropTypes.func,
  onReset: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
};

AdvancedSearch.defaultProps = {
  autoFocus: true,
  classes: { paper: '' },
  inputValue: '',
  children: null,
  tooltip: 'Advanced Search',
  onChange: null,
  onReset: () => {},
  onSearch: () => {},
  placeholder: '',
};

export default AdvancedSearch;
