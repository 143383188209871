/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IconButton,
  Icon,
  Tooltip,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Typography,
} from '@material-ui/core';
import { DateHelper } from '@rss/common';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import AttachmentHelper from './AttachmentHelper';

const AttachmentPrint = (props) => {
  const { attachments = [], optional, label, attachmentUrl } = props;
  return (
    <div className="my-4 pb-0 pr-8">
      <div className="text-justify text-14">
        {label}
        {!optional && !attachments?.length && (
          <Typography className="inline pl-8 italic text-danger-800">Incomplete</Typography>
        )}
      </div>
      <List className="py-0">
        {!isEmpty(attachments) &&
          attachments.map((attachment) => (
            <ListItem className="pl-0 text-justify text-14" key={attachment._id}>
              <ListItemIcon classes={{ root: 'min-w-36' }} className="print:hidden">
                <Icon data-cy="Tab_completion_icon" color="primary">
                  attach_file
                </Icon>
              </ListItemIcon>
              <div>
                <Link
                  component="button"
                  variant="body1"
                  onClick={() =>
                    AttachmentHelper.handleDownload(
                      attachment.filename,
                      attachment.token,
                      attachment._id,
                      attachmentUrl,
                    )
                  }>
                  {attachment.title ? `Title: ${attachment.title}` : attachment.filename}
                </Link>
                <ListItemText
                  secondary={`Date Attached: ${DateHelper.formatDate(attachment.uploadDate)}`}
                  className="print:hidden"
                />
              </div>
              <ListItemSecondaryAction className="print:hidden">
                <Tooltip id="tooltip-icon-download" title="Download file">
                  <IconButton
                    aria-label="Download"
                    onClick={() =>
                      AttachmentHelper.handleDownload(
                        attachment.filename,
                        attachment.token,
                        attachment._id,
                        attachmentUrl,
                      )
                    }>
                    <Icon>get_app</Icon>
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        {isEmpty(attachments) && (
          <ListItem>
            <Typography className="italic text-grey-400">No Attachment</Typography>
          </ListItem>
        )}
      </List>
    </div>
  );
};

AttachmentPrint.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.any),
  label: PropTypes.string.isRequired,
  optional: PropTypes.bool,
  attachmentUrl: PropTypes.string,
};

AttachmentPrint.defaultProps = {
  attachments: [],
  optional: false,
  attachmentUrl: undefined,
};

export default AttachmentPrint;
