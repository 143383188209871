/* eslint-disable react/no-array-index-key */
import { Checkbox, Divider, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import {
  DateHelper,
  FqHelper,
  FqType,
  SHORT_DATE_FORMAT,
  SHORT_DATETIME_FORMAT,
  SHORT_DATETIME_24H_FORMAT,
} from '@rss/common';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import { MARKDOWN_OPTIONS, parseArrayToString } from '../../shared/helper';
import IconEmpty from '../IconEmpty';
import AttachmentPrint from './AttachmentPrint';
import CommentItemReadOnly from './CommentItemReadOnly';
import ExternalFiles from './ExternalFiles';
import FqPrintBasic from './FqPrintBasic';
import { shouldDisplay } from './Helper';

const printCheckbox = (question, option, checked = false) => (
  <FormControlLabel
    classes={{ root: 'pl-8', label: 'pl-8' }}
    className="my-2 mt-2 w-full items-start text-grey-400"
    key={`${option.value}-${question.label}`}
    control={
      <Checkbox
        checked={checked}
        classes={{ root: 'p-2' }}
        className="-my-2 text-grey-400"
        variant="outlined"
        disabled
      />
    }
    label={<span className="text-justify text-14 font-medium text-black print:font-normal">{option.label}</span>}
  />
);

const printAddress = (question, response) => {
  const { addressLine1 = '', addressLine2 = '', city = '', state = '', zip = '' } = response;
  return (
    <div className="grid print:pb-6">
      <div className="my-4 text-14 font-normal text-grey-400 print:font-medium print:text-black">{question.label}</div>
      <div className="flex flex-col gap-4">
        <div className="flex font-medium print:font-normal">
          <div className="mr-8">{addressLine1}</div>
          <div>{addressLine2}</div>
        </div>
        <div className="whitespace-pre-wrap text-justify font-medium print:font-normal">
          <div>
            {city}
            {state ? ',' : ''} {state} {zip}
          </div>
        </div>
      </div>
    </div>
  );
};

const getSelectedOptionValue = (question, response) => {
  if (isEmpty(question?.options)) {
    return question.valueType === 'string' ? response : response?.label;
  }

  return (
    question?.options?.find((option) => option.value === (question.valueType === 'string' ? response : response?.value))
      ?.label || (question.valueType === 'string' ? response : response?.label)
  );
};

class FqQuestionPrint extends Component {
  render() {
    const {
      attachmentUrl,
      comments,
      question,
      response,
      parentResponses,
      headerLabel,
      hideCompleteCheck,
      configuration,
    } = this.props;
    const { show24HourTime } = configuration;
    return (
      <div className={question.className || 'w-full'} key={question.key}>
        {(() => {
          switch (question.type) {
            case FqType.GROUP:
              return (
                <>
                  {question.label && (
                    <Markdown
                      options={MARKDOWN_OPTIONS}
                      className="mb-10 text-14 font-normal text-grey-400">
                      {question.label}
                    </Markdown>
                  )}
                  <div className="mt-2 flex w-full flex-wrap">
                    {question.questions.map((q) => (
                      <FqQuestionPrint
                        key={q.key}
                        question={q}
                        response={response && response[q.key]}
                        comments={comments}
                        parentResponses={response}
                        headerLabel={headerLabel}
                        hideCompleteCheck={hideCompleteCheck}
                        type={question.type}
                      />
                    ))}
                  </div>
                </>
              );

            case FqType.ADDRESS:
              return printAddress(question, response);

            case FqType.TEXTAREA:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={response}
                  allowSplitPrint
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );
            case FqType.SWITCH:
            case FqType.INPUT:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={response}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question?.inputProps?.type || question.type || 'text'}
                  question={question}
                  parentResponses={parentResponses}
                />
              );
            case FqType.LABEL:
              return (
                <FqPrintBasic
                  label={question.altLabel || question.label}
                  response={response}
                  hideCompleteCheck
                  headerLabel={headerLabel}
                  info={question.info}
                  type={question.type}
                  question={question}
                />
              );

            case FqType.RADIO:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={
                    response &&
                    question.options.find((option) => option.value === response) &&
                    question.options.find((option) => option.value === response).label
                  }
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );

            case FqType.DROPDOWN:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={getSelectedOptionValue(question, response)}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );

            case FqType.MULTISELECT:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={parseArrayToString(response?.map((res) => getSelectedOptionValue(question, res)))}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );

            case FqType.CHECKBOX: {
              if (question.options && question.options.length > 0) {
                const markdownLabel = [
                  question?.label?.trim(),
                  question.required && !(response && response.length)
                    ? '<span className="ml-10 text-danger-800 inline italic">Incomplete</span>'
                    : '',
                ].join('');

                return (
                  <>
                    {/* hide question label when none of checkboxes are checked */}
                    {/* show question label and validation when question is required */}

                    <Markdown
                      options={MARKDOWN_OPTIONS}
                      className="my-2 mt-4 text-14 font-normal text-grey-400 print:font-medium print:text-black">
                      {markdownLabel}
                    </Markdown>

                    <FormGroup>
                      {/* eslint-disable-next-line max-len */}
                      {/* If there are more than one checkboxes for a question AND at least one of them is checked show only checked */}
                      {question.options &&
                        question.options.length > 1 &&
                        question?.options
                          .filter(
                            (option) =>
                              response &&
                              response.length > 0 &&
                              Array.isArray(response) &&
                              !!response.filter((v) => v.value === option.value).length > 0,
                          )
                          .map((option) =>
                            printCheckbox(question, option, !!response?.filter((v) => v.value === option.value).length),
                          )}
                      {/* If there IS ONLY one checkbox for a question , show it regardless of checked or not */}
                      {question.options &&
                        question.options.length === 1 &&
                        question.options.map((option) =>
                          printCheckbox(
                            question,
                            option,
                            Array.isArray(response) && !!response?.filter((v) => v.value === option.value).length,
                          ),
                        )}
                    </FormGroup>
                  </>
                );
              }
              return (
                <FqPrintBasic
                  label={question.label}
                  response={response}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );
            }
            case FqType.DATEPICKER:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={DateHelper.formatDate(response, SHORT_DATE_FORMAT)}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                  parentResponses={parentResponses}
                />
              );
            case FqType.DATETIMEPICKER:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={DateHelper.formatDate(
                    response,
                    show24HourTime ? SHORT_DATETIME_24H_FORMAT : SHORT_DATETIME_FORMAT,
                  )}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                  parentResponses={parentResponses}
                />
              );
            case FqType.TIMEPICKER:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={DateHelper.formatDate(response, show24HourTime ? 'H:mm' : 'h:mm a')}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );

            case FqType.ATTACHMENT:
              return (
                <AttachmentPrint
                  label={question.label}
                  attachments={response}
                  optional={!question.required}
                  attachmentUrl={attachmentUrl}
                />
              );

            case FqType.YYYY:
              return (
                shouldDisplay(question, parentResponses) && (
                  <div className="ml-24">
                    <FqPrintBasic label={question.label || ''} type={question.type} />
                    {question.questions.map((ques) => (
                      <div key={ques.key}>
                        {response &&
                          response.map((res) => (
                            <FqQuestionPrint
                              key={res.id}
                              question={ques}
                              comments={comments}
                              response={res.item && res.item[ques.key]}
                              parentResponses={parentResponses}
                              hideCompleteCheck={hideCompleteCheck}
                              type={question.type}
                            />
                          ))}
                      </div>
                    ))}
                  </div>
                )
              );
            case FqType.DEPENDENT:
              return (
                shouldDisplay(question, parentResponses) && (
                  <div className="mb-4 ml-24">
                    <FqPrintBasic label={question.label || ''} type={question.type} hideCompleteCheck />
                    {question.questions.map((ques, dependentIndex) => (
                      <Fragment key={dependentIndex}>
                        <FqQuestionPrint
                          key={ques.key}
                          question={ques}
                          comments={comments}
                          response={response && response[ques.key]}
                          parentResponses={response}
                          hideCompleteCheck={hideCompleteCheck}
                          type={question.type}
                        />
                      </Fragment>
                    ))}
                  </div>
                )
              );
            case FqType.LIBRARYTYPE:
              return (
                <div className="px-0" key={question.key}>
                  {question.label && (
                    <FqPrintBasic
                      label={question.label || ''}
                      response={response}
                      hideCompleteCheck={hideCompleteCheck}
                      type={question.type}
                      question={question}
                    />
                  )}
                  <div className={clsx(question.label && '-mt-8 ml-8')}>
                    {response?.map((material) => (
                      <div className="p-0" key={material.name}>
                        <Typography variant="subtitle1" className="ml-16 list-item pb-4">
                          {material.name} - {question.followupLabel ? question.followupLabel : 'Type'}:{' '}
                          {get(material, question.followup)}
                        </Typography>
                        {question.questions
                          .filter(
                            (q) =>
                              (q?.parentType?.length && q?.parentType.some((p) => material?.tags?.includes(p))) ||
                              question.showDetailsLink,
                          )
                          .map((q) => (
                            <div className="px-20" key={`${material.name}-${q.key}`}>
                              <FqQuestionPrint
                                key={q.key}
                                question={q}
                                response={material && material[q.key]}
                                comments={comments}
                                parentResponses={material}
                                hideCompleteCheck={hideCompleteCheck}
                                type={question.type}
                              />
                            </div>
                          ))}
                      </div>
                    ))}
                    {question.label && !FqHelper.hasValue(response) && <IconEmpty classes={{ icon: '-mb-8 -ml-8' }} />}
                  </div>
                </div>
              );
            case FqType.ADD_ANOTHER:
              return (
                <>
                  {response?.map((res, index) => {
                    const showDivider = response.length > 1 && index < response.length - 1;
                    return (
                      <div className="mb-8" key={res.id}>
                        <FqPrintBasic label={question.label || ''} hideCompleteCheck type={question.type} />
                        <div className="mt-1">
                          {question.questions.map((ques, dependentIndex) => (
                            <Fragment key={dependentIndex}>
                              <FqQuestionPrint
                                key={ques.key}
                                question={ques}
                                comments={comments}
                                response={res && res?.item && res.item[ques.key]}
                                parentResponses={res?.item}
                                hideCompleteCheck={hideCompleteCheck}
                                type={question.type}
                              />
                            </Fragment>
                          ))}
                        </div>
                        {showDivider && <Divider />}
                      </div>
                    );
                  })}
                </>
              );
            case FqType.PERMIT_SITE:
              return (
                <div className="px-0" key={question.key}>
                  {question.label && (
                    <FqPrintBasic
                      label={question.label || ''}
                      response={response}
                      hideCompleteCheck={hideCompleteCheck}
                      type={question.type}
                      question={question}
                    />
                  )}
                  <div className={clsx(question.label && '-mt-16 ml-4')}>
                    {response
                      ?.filter(({ item }) => !!FqHelper.hasValue(item))
                      .map((res, index) => {
                        const showDivider = response.length > 1 && index < response.length - 1;
                        return (
                          <div className="px-0 py-0" key={res.id}>
                            <Typography className="text-16 font-semibold" variant="overline">
                              {`Site: ${res?.item?.value}` || ''}
                            </Typography>
                            <div className="flex w-full flex-wrap pl-16" key={res.id}>
                              {question.questions.map((ques) => (
                                <FqQuestionPrint
                                  key={`${res.id}-${ques.key}`}
                                  question={ques}
                                  comments={comments}
                                  response={res && res?.item && res.item[ques.key]}
                                  parentResponses={res?.item}
                                />
                              ))}
                            </div>
                            {showDivider ? <Divider /> : null}
                          </div>
                        );
                      })}
                  </div>
                  {question.label && !FqHelper.hasValue(response?.filter(({ item }) => !!FqHelper.hasValue(item))) && (
                    <IconEmpty classes={{ icon: 'mt-8 -mb-8' }} />
                  )}
                </div>
              );
            case FqType.PERSON_SEARCH:
              return (
                <>
                  <Markdown options={MARKDOWN_OPTIONS} className="my-2 text-14 font-normal text-grey-400">
                    {question.label}
                  </Markdown>
                  {response?.firstName && response?.lastName && (
                    <div className="font-medium">
                      {response.firstName} {response.lastName}
                      <Typography variant="caption" color="textSecondary">
                        {' '}
                        ({response.email})
                      </Typography>
                    </div>
                  )}
                </>
              );

            case FqType.REGULATION_SEARCH:
              return parentResponses[question.key]?.map((res) => (
                <li key={res?.id} className="my-8 whitespace-pre-wrap pl-12 font-medium">
                  {res?.value}
                </li>
              ));

            case FqType.BUILDING_SEARCH:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={response.name}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );

            case FqType.OCCUPATION_SEARCH:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={response.label}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );

            case FqType.DEPARTMENT_SEARCH:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={response.label}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                />
              );

            case FqType.RECIPIENTS:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={parseArrayToString(
                    question.valueType === 'email' ? response?.map(({ email }) => email) : [],
                  )}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                  parentResponses={parentResponses}
                />
              );

            case FqType.EXTERNAL_FILE:
              return (
                <div className="mb-8">
                  <ExternalFiles question={question} values={response || []} />
                </div>
              );
            case FqType.RICHTEXT:
              return (
                <FqPrintBasic
                  label={question.label}
                  response={response}
                  optional={!question.required}
                  hideCompleteCheck={hideCompleteCheck}
                  type={question.type}
                  question={question}
                  parentResponses={parentResponses}
                />
              );
            default:
              return <div>{question.type}</div>;
          }
        })()}
        {comments?.[question?.key] && Array.isArray(comments?.[question?.key]) && (
          <blockquote className="my-8 border-l-4 border-solid border-primary-300 pl-8">
            {comments?.[question?.key]?.map((comment) => (
              <div key={comment.id}>
                <CommentItemReadOnly comment={comment} />
              </div>
            ))}
          </blockquote>
        )}
      </div>
    );
  }
}

FqQuestionPrint.propTypes = {
  attachmentUrl: PropTypes.string,
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  response: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  parentResponses: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.bool]),
  comments: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.arrayOf(PropTypes.any)]),
  headerLabel: PropTypes.bool,
  hideCompleteCheck: PropTypes.bool,
  configuration: PropTypes.shape({ [PropTypes.string]: PropTypes.any }),
};

FqQuestionPrint.defaultProps = {
  attachmentUrl: '',
  response: null,
  parentResponses: null,
  comments: null,
  configuration: {},
  headerLabel: false,
  hideCompleteCheck: false,
};

export default FqQuestionPrint;
